.Typist {
  display: inline;
}

li.active > a {
  color: #2f55d4 !important;
}

.pagination .active a {
  color: #ffffff !important;
}

a {
  &:hover {
    cursor: pointer;
  }
}

.accordian-custom {
  border: none;
}

#mt-1 {
  margin-top: 5px !important;
}

#mb-1 {
  margin-top: 5px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.masonry-container {
  width: 100%;
  margin: auto;
}
.masonry {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  width: 100%;
  margin: auto;
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  flex-grow: 1;
}
.tile {
  img {
    width: 100%;
  }
}

.indicator-inactive,
.indicator-active {
  height: 12px !important;
  width: 6px !important;
  border-radius: 4px;
  border: none;
  margin-left: 7px;
  margin-right: 7px;
}

.indicator-inactive {
  background-color: #2f55d4 !important;
  opacity: 0.6;
}
.indicator-active {
  background-color: #2f55d4 !important;
  transform: rotate(45deg);
}

.custom-owl-dots {
  margin-top: 10px;
  text-align: center;
}

.classForContainer {
  position: fixed;
  right: -100px;
  bottom: 30px;
  transition: all 0.5s ease;
  cursor: pointer;
  background-color: $primary;
  padding: 5px;
  align-items: center;
  border-radius: 5px !important;
  outline: none;
  
  &:hover {
    background-color: $primary !important;
    transform: rotate(45deg);
    svg {
      transform: rotate(-45deg);
      stroke: $white !important;
    }
  }

  svg {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    overflow: hidden;
    margin-top: 2px;
    margin-left: 5px;
    fill: none;
    stroke: $white !important;
    transition: all 0.5s ease;
  }
}

.classForTransition {
  right: 30px;
}
//masonry

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

.accordion {
  .card {
    .faq {
      > .active-link {
        color: $primary !important;
      }
    }
  }
}

.hover-border {
  .nav-link {
    &:focus,
    &.active,
    &:active {
      outline: none !important;
      border: transparent !important;
    }
    &:hover {
      border-color: transparent !important;
    }
  }
}
.custom-dots {
  .indicator-active {
    background-color: #2f55d4 !important;
  }
}
.custom-dots {
  .indicator-inactive {
    background-color: #6486e4 !important;
  }
}

.close {
  background: transparent;
  border: 0;
  font-size: 24px;
  padding: 1.35rem 1.25rem;
  color: #000 !important;
  background: transparent
    escape-svg(
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#fff'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")
    )
    center / 1em auto no-repeat;
  position: absolute !important;
  top: -7px;
  right: 20px;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
}

[class^='uil-'],
[class*=' uil-'] {
  &:before {
    margin: 0;
  }
}
.footer-border-bottom {
  border-bottom: 1px solid #283353;
}

// container
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
  @media (min-width: 1200px){
      max-width: 1140px;
  }
}

// react slide show
.each-slide {
  display: flex;
  width: 100%;
  height: 400px;
}

.each-slide>div {
  width: 75%;
}

.each-slide>div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.each-slide p {
  width: 25%;
  font-size: 1em;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.rs-theme-light {
  --rs-gray-300: #d9d9d9;
  --rs-gray-500: #a6a6a6;
  --rs-primary-500: #3498ff;
  --rs-timeline-indicator-bg: var(--rs-gray-300);
  --rs-timeline-indicator-active-bg: var(--rs-primary-500);
}
.rs-theme-dark {
  --rs-gray-300: #858b94;
  --rs-gray-500: #5c6066;
  --rs-primary-500: #34c3ff;
  --rs-timeline-indicator-bg: var(--rs-gray-500);
  --rs-timeline-indicator-active-bg: var(--rs-primary-500);
}
.rs-theme-high-contrast {
  --rs-gray-300: #858b94;
  --rs-gray-500: #5c6066;
  --rs-primary-500: #ffff00;
  --rs-timeline-indicator-bg: var(--rs-gray-500);
  --rs-timeline-indicator-active-bg: var(--rs-primary-500);
}
/* stylelint-disable */
*[class*='rs-'] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
*[class*='rs-']::before,
*[class*='rs-']::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.rs-timeline {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  direction: ltr;
}
.rs-timeline-item {
  position: relative;
  text-align: left;
}
.rs-timeline-item:not(:last-child) .rs-timeline-item-content {
  padding-bottom: 18px;
}
.rs-timeline-item-dot {
  position: absolute;
  top: -2px;
}
.rs-timeline-item-dot::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: #d9d9d9;
  background-color: var(--rs-timeline-indicator-bg);
  border-radius: 50%;
}
.rs-timeline-item-custom-dot {
  text-align: center;
}
.rs-timeline-item-custom-dot::before {
  display: none;
}
.rs-timeline-item-active .rs-timeline-item-dot::before {
  background-color: #3498ff;
  background-color: #d9d9d9;
}
.rs-timeline-item-tail {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #d9d9d9;
  background-color: #d9d9d9;
}
.rs-timeline-item:first-child .rs-timeline-item-tail {
  top: 5px;
}
.rs-timeline-item:last-child .rs-timeline-item-tail {
  bottom: auto;
  height: 15px;
}
.rs-timeline-endless .rs-timeline-item:last-child .rs-timeline-item-tail {
  height: auto;
  min-height: 38px;
}
.rs-timeline-item:only-child .rs-timeline-item-tail {
  display: none;
}
.rs-timeline-align-left .rs-timeline-item-dot {
  left: -2px;
}
.rs-timeline-align-left .rs-timeline-item-tail {
  left: 4px;
}
.rs-timeline-align-left .rs-timeline-item {
  padding-left: 22px;
}
.rs-timeline-align-left .rs-timeline-item {
  padding-left: 22px;
}
.rs-timeline-align-right .rs-timeline-item-dot {
  right: 0;
}
.rs-timeline-align-right .rs-timeline-item-tail {
  right: 4px;
}
.rs-timeline-align-right .rs-timeline-item {
  padding-right: 22px;
}
.rs-timeline-align-right .rs-timeline-item-content {
  text-align: right;
}
.rs-timeline-align-alternate .rs-timeline-item-dot {
  left: calc(50% - 5px);
}
.rs-timeline-align-alternate .rs-timeline-item-tail {
  left: calc(50% - 1px);
}
.rs-timeline-align-alternate .rs-timeline-item-content {
  width: 50%;
}
.rs-timeline-align-alternate .rs-timeline-item:nth-child(even) .rs-timeline-item-content {
  text-align: right;
}
.rs-timeline-align-alternate .rs-timeline-item:nth-child(odd) {
  text-align: right;
}
.rs-timeline-align-alternate .rs-timeline-item:nth-child(odd) .rs-timeline-item-content {
  display: inline-block;
  text-align: left;
}
.rs-timeline-with-time .rs-timeline-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
}
.rs-timeline-with-time .rs-timeline-item-dot {
  left: calc(50% - 5px);
}
.rs-timeline-with-time .rs-timeline-item-tail {
  left: calc(50% - 1px);
}
.rs-timeline-with-time .rs-timeline-item-time,
.rs-timeline-with-time .rs-timeline-item-content {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 50%;
          flex: 1 0 50%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-timeline-with-time .rs-timeline-item-time,
  .rs-timeline-with-time .rs-timeline-item-content {
    max-width: 50%;
  }
}
.rs-timeline-align-alternate .rs-timeline-item:nth-child(2n),
.rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item {
  padding-right: 0;
}
.rs-timeline-with-time.rs-timeline-align-left .rs-timeline-item-time {
  text-align: right;
}
.rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item-time {
  text-align: left;
}
.rs-timeline-with-time.rs-timeline-align-left .rs-timeline-item-time,
.rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item-content,
.rs-timeline-align-alternate .rs-timeline-item:nth-child(2n + 1) .rs-timeline-item-time,
.rs-timeline-align-alternate .rs-timeline-item:nth-child(2n) .rs-timeline-item-content {
  padding-right: 17px;
}
.rs-timeline-with-time.rs-timeline-align-left .rs-timeline-item-content,
.rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item-time,
.rs-timeline-align-alternate .rs-timeline-item:nth-child(2n + 1) .rs-timeline-item-content,
.rs-timeline-align-alternate .rs-timeline-item:nth-child(2n) .rs-timeline-item-time {
  padding-left: 17px;
}
